import { useMutation } from "@apollo/client";

import {
    AddAssetPropertyMutation,
    AddAssetPropertyMutationVariables,
    DeleteAssetPropertyMutation,
    DeleteAssetPropertyMutationVariables,
    UpdateAssetPropertyMutation,
    UpdateAssetPropertyMutationVariables,
} from "graphql-types/graphql";

import {
    ADD_ASSET_PROPERTY,
    DELETE_ASSET_PROPERTY,
    UPDATE_ASSET_PROPERTY,
} from "./asset-property.query";
import { ModalProperty } from "../types";

export const usePropertyMutations = (assetId?: string) => {
    const [addAssetProperty] = useMutation<
        AddAssetPropertyMutation,
        AddAssetPropertyMutationVariables
    >(ADD_ASSET_PROPERTY, {
        update(cache) {
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    const [updateAssetProperty] = useMutation<
        UpdateAssetPropertyMutation,
        UpdateAssetPropertyMutationVariables
    >(UPDATE_ASSET_PROPERTY, {
        update(cache) {
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    const [deleteAssetProperty] = useMutation<
        DeleteAssetPropertyMutation,
        DeleteAssetPropertyMutationVariables
    >(DELETE_ASSET_PROPERTY, {
        update(cache) {
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    async function onSaveProperty(property: ModalProperty, parentId?: string) {
        if (!assetId || !property.objectType || !property.ownershipType) {
            return;
        }

        await addAssetProperty({
            variables: {
                input: {
                    assetId,
                    propertyName: property.name,
                    identifier: property.identifier,
                    propertyObjectType: property.objectType,
                    propertyOwnershipType: property.ownershipType,
                    nationalPropertyType: property.nationalPropertyType,
                    buildingArea: Number(property.buildingArea),
                    constructionDate: property.constructionDate,
                    purchaseDate: property.purchaseDate,
                    saleDate: property.saleDate,
                    address: JSON.stringify(property.address),
                    parentPropertyId: parentId ?? "",
                },
            },
        });
    }

    async function onEditProperty(
        property: Partial<ModalProperty>,
        propertyId: string
    ) {
        if (!propertyId) {
            return;
        }

        await updateAssetProperty({
            variables: {
                input: {
                    id: propertyId,
                    update: {
                        ...(property as any),
                        address: property.address
                            ? JSON.stringify(property.address)
                            : undefined,
                        buildingArea: Number(property.buildingArea),
                    },
                },
            },
        });
    }

    function onPropertyDelete(propertyId: string) {
        deleteAssetProperty({ variables: { propertyId } });
    }

    return {
        onSaveProperty,
        onEditProperty,
        onPropertyDelete,
    };
};
