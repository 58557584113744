import { createContext, useContext, useState, PropsWithChildren } from "react";

import {
    AssessmentVertical,
    PropertyOwnershipType,
} from "graphql-types/graphql";

// Context Shape
type AssetPropertyFilters = {
    ownershipTypes: PropertyOwnershipType[];
    setOwnershipTypes: (type: PropertyOwnershipType[]) => void;
    verticals: AssessmentVertical[];
    setVerticals: (verticals: AssessmentVertical[]) => void;
    resetFilters: () => void;
    propertiesCount: number;
    setPropertiesCount: (count: number) => void;
};

// Create the context with a default value
const AssetPropertyFiltersContext = createContext<
    AssetPropertyFilters | undefined
>(undefined);

// Provider component
export const AssetPropertyFiltersProvider = (props: PropsWithChildren) => {
    const { children } = props;

    const [ownershipTypes, setOwnershipTypes] = useState<
        PropertyOwnershipType[]
    >([]);

    const [verticals, setVerticals] = useState<AssessmentVertical[]>([]);

    const resetFilters = () => {
        setOwnershipTypes([]);
        setVerticals([]);
    };

    const [propertiesCount, setPropertiesCount] = useState<number>(0);

    return (
        <AssetPropertyFiltersContext.Provider
            value={{
                ownershipTypes,
                setOwnershipTypes,
                verticals,
                setVerticals,
                resetFilters,
                propertiesCount,
                setPropertiesCount,
            }}
        >
            {children}
        </AssetPropertyFiltersContext.Provider>
    );
};

export const useAssetPropertyFilters = (): AssetPropertyFilters => {
    const context = useContext(AssetPropertyFiltersContext);
    if (!context) {
        throw new Error(
            "useAssetPropertyFilters must be used within a AssetPropertyFilterProvider"
        );
    }
    return context;
};
