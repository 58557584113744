import { useQuery } from "@apollo/client";
import {
    Button,
    CircularProgress,
    Alert,
    Typography,
    Box,
} from "@mui/material";
import { GridRowModel, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { clsx } from "clsx";
import { useState, useMemo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { GetOrgDataForOnboardingQuery } from "graphql-types/graphql";
import { FormControls, LoadingContainer, SuccessBox } from "styling/box";

import { CSVProperty } from "./dataImportTypes";
import { useCreateLocationsAndIntegrations } from "./hooks";
import { GET_ORG_DATA_FOR_ONBOARDING } from "./importQueries";
import { getApolloErrorMessages, parseResults } from "./utilities";
import GoToSecretPageButton from "../../components/GoToSecretPageButton/GoToSecretPageButton";
import PapaParse from "../../components/PapaParse/PapaParse";
import { Table, TablePagination } from "../../components/Table";

const columns: GridColDef[] = [
    { field: "externalId", headerName: "externalId" },
    { field: "name", headerName: "name" },
    { field: "purchaseDate", headerName: "purchaseDate", width: 200 },
    { field: "saleDate", headerName: "saleDate" },
    { field: "constructionDate", headerName: "constructionDate", width: 200 },
    { field: "legalOwner", headerName: "legalOwner", width: 230 },
    { field: "ownership", headerName: "ownership" },
    { field: "buildingArea", headerName: "buildingArea", width: 200 },
    { field: "management", headerName: "management", width: 200 },
    {
        field: "countryCode",
        width: 230,
        headerName: "countryCode",
        cellClassName: (params: GridCellParams) =>
            clsx({
                error: params.row.errors.countryCode,
            }),
    },
    { field: "address", headerName: "address", width: 330 },
    {
        field: "integrations",
        headerName: "integrations",
        width: 300,
    },
    { field: "bfeCodes", headerName: "BFE Codes" },
];

const AlertMessage = ({ error }: { error: string }) => {
    const { t } = useTranslation();

    const alertMessage = useMemo(
        () =>
            error
                ? t(
                      "dataUploadPage.errorMessage",
                      "There is an error in one or more rows. Please fix the errors and upload the file again ☺️"
                  )
                : t(
                      "dataUploadPage.successMessage",
                      "You successfully upload the data!"
                  ),
        [error, t]
    );

    return (
        <SuccessBox>
            <Alert variant="filled" severity={error ? "error" : "success"}>
                {alertMessage}
                {error && (
                    <Typography sx={{ ml: 4 }}>
                        <Trans i18nKey={"dataUploadPage.errors.generic"}>
                            Here is the error message:
                            <br />
                            {{ error }}
                            <br />
                            If this is unclear, contact the tech team !
                        </Trans>
                    </Typography>
                )}
            </Alert>
        </SuccessBox>
    );
};

const CustomerImportPage = () => {
    const { t } = useTranslation();

    const [uploadedLocations, setUploadedLocations] = useState<CSVProperty[]>(
        []
    );

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    const [handleLocationCreation] = useCreateLocationsAndIntegrations();

    const removeUpload = (event: Event) => {
        event.preventDefault();
        setUploadedLocations([]);
        setSubmitted(false);
        setError("");
    };

    const handleUpload = (data: Array<CSVProperty>) => {
        setUploadedLocations(data);
        setSubmitted(false);
        setError("");
    };

    const { data } = useQuery<GetOrgDataForOnboardingQuery>(
        GET_ORG_DATA_FOR_ONBOARDING
    );

    const locations = useMemo(() => {
        const { locations } = data?.me.organization ?? {};

        if (!locations) {
            return null;
        }

        return locations.edges.map((edge) => edge.node);
    }, [data]);

    const formattedLocations = useMemo(() => {
        if (!uploadedLocations) {
            return;
        }

        return parseResults(uploadedLocations);
    }, [uploadedLocations]);

    const handleSubmit = useCallback(() => {
        setLoading(true);

        if (
            !formattedLocations ||
            !formattedLocations.locations ||
            !locations
        ) {
            return;
        }

        handleLocationCreation(formattedLocations.locations, locations)
            .then(() => {
                setSubmitted(true);
                setLoading(false);
                setUploadedLocations([]);
            })
            .catch((error) => {
                const errorMessage = getApolloErrorMessages(error.message, t);
                setLoading(false);

                setError(errorMessage ?? "");
            });
    }, [t, handleLocationCreation, formattedLocations, locations]);

    const hasErrors = useMemo(() => {
        const hasError =
            formattedLocations &&
            formattedLocations.rows.some((row) => row.hasErrors);

        if (hasError) {
            setError(
                t(
                    "dataUploadPage.errorMessage",
                    "There is an error in one or more rows. Please fix the errors and upload the file again ☺️"
                )
            );
        }

        return hasError;
    }, [formattedLocations, t]);
    const rows: GridRowModel[] = formattedLocations?.rows || [];

    return (
        <Box>
            <GoToSecretPageButton />

            <Typography variant="h2" my={2}>
                {t("dataUploadPage.title", "Upload data")}
            </Typography>
            <PapaParse
                removeUpload={removeUpload}
                handleUpload={handleUpload}
                handleFile={() => ({})}
            />

            {uploadedLocations.length > 0 && (
                <Box marginTop={5}>
                    <Table
                        columns={columns}
                        rows={rows}
                        sortable={true}
                        slotProps={{
                            pagination: {
                                ActionsComponent: TablePagination,
                            },
                        }}
                    />

                    <FormControls my={2}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={
                                hasErrors ||
                                !formattedLocations ||
                                !formattedLocations.locations
                            }
                        >
                            {t("dataUploadPage.submit", "Submit data")}
                            {loading && (
                                <LoadingContainer>
                                    <CircularProgress color="secondary" />
                                </LoadingContainer>
                            )}
                        </Button>
                    </FormControls>
                </Box>
            )}

            {(error || submitted) && <AlertMessage error={error} />}
        </Box>
    );
};

export default CustomerImportPage;
