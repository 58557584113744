import { groupBy } from "lodash";

import {
    DexmaMeterLocationFragment,
    ElectralinkMeterLocationFragment,
    EntolabsMeterLocationFragment,
    GetOisMeterLocationsQuery,
    HoforMeterLocationFragment,
    IntegrationOisLocationIntegrationData,
    IntegrationType,
    IntegrationType as NewIntegrationType,
    SupportedIntegrationForOnboarding,
    TechemMeterLocationFragment,
    VitaniMeterLocationFragment,
} from "graphql-types/graphql";

import { UNSUPPORTED_INTEGRATION_TYPE } from "../../utils";
import { LocationMapping } from "../sharedModalTypes";

export const getMutationPayloadForIntegration = (
    integrationType: SupportedIntegrationForOnboarding,
    locationMapping: LocationMapping
) => {
    const { data } = locationMapping;

    switch (integrationType) {
        case SupportedIntegrationForOnboarding.DEXMA:
            return buildDexmaMutationPayload(
                locationMapping,
                data as DexmaMeterLocationFragment
            );
        case SupportedIntegrationForOnboarding.TECHEM:
            return buildTechemMutationPayload(
                locationMapping,
                data as TechemMeterLocationFragment
            );
        case SupportedIntegrationForOnboarding.VITANI:
            return buildVitaniMutationPayload(
                locationMapping,
                data as VitaniMeterLocationFragment
            );
        case SupportedIntegrationForOnboarding.HOFOR:
            return buildHoforMutationPayload(
                locationMapping,
                data as HoforMeterLocationFragment
            );
        case SupportedIntegrationForOnboarding.OIS:
            return buildOISMutationPayload(locationMapping);
        case SupportedIntegrationForOnboarding.ENTOLABS:
            return buildEntolabsMutationPayload(
                locationMapping,
                data as EntolabsMeterLocationFragment
            );
        case SupportedIntegrationForOnboarding.ELECTRALINK:
            return buildElectralinkMutationPayload(
                locationMapping,
                data as ElectralinkMeterLocationFragment
            );
        default:
            throw new Error(UNSUPPORTED_INTEGRATION_TYPE);
    }
};

const buildDexmaMutationPayload = (
    locationMapping: LocationMapping,
    data: DexmaMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;
    const { activity, id, key, name, reference_devices } = data;

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.DEXMA],
        assetId,
        data: JSON.stringify({
            activity,
            id,
            key,
            name,
            reference_devices,
        }),
        originId: key,
        startedAt,
        endedAt,
    };
};

const buildTechemMutationPayload = (
    locationMapping: LocationMapping,
    data: TechemMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.TECHEM],
        assetId,
        data: JSON.stringify({
            property_number: data.property_number,
        }),
        originId: data.property_number,
        startedAt,
        endedAt,
    };
};

const buildVitaniMutationPayload = (
    locationMapping: LocationMapping,
    data: VitaniMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.VITANI],
        assetId,
        data: JSON.stringify({
            DirectoryID: data.DirectoryID,
        }),
        originId: data.DirectoryID.toString(),
        startedAt,
        endedAt,
    };
};

const buildHoforMutationPayload = (
    locationMapping: LocationMapping,
    data: HoforMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.HOFOR],
        assetId,
        data: JSON.stringify({
            installationNumber: data.installationNumber,
        }),
        originId: data.installationNumber.toString(),
        startedAt,
        endedAt,
    };
};

const buildOISMutationPayload = (locationMapping: LocationMapping) => {
    const { assetId, originId, startedAt, endedAt } = locationMapping;

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.OIS],
        assetId,
        data: JSON.stringify({}),
        originId,
        startedAt,
        endedAt,
    };
};

const buildEntolabsMutationPayload = (
    locationMapping: LocationMapping,
    data: EntolabsMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;
    const { meterId, ...rest } = data;

    const integrationData = {
        ...rest,
        meterType: rest.meterType.toLowerCase(),
        address: rest.address ? rest.address : undefined,
    };

    return {
        type: IntegrationType[SupportedIntegrationForOnboarding.ENTOLABS],
        assetId,
        data: JSON.stringify(integrationData),
        originId: meterId,
        startedAt,
        endedAt,
    };
};

export const buildElectralinkMutationPayload = (
    locationMapping: LocationMapping,
    data: ElectralinkMeterLocationFragment
) => {
    const { assetId, startedAt, endedAt } = locationMapping;
    const { hashedMPAN, partialMPAN, postCode, address } = data;

    return {
        type: NewIntegrationType.ELECTRALINK,
        assetId,
        data: JSON.stringify({
            address,
            hashedMPAN,
            partialMPAN,
            postCode,
        }),
        originId: hashedMPAN,
        startedAt,
        endedAt,
    };
};

export const reformatOISLocationIntegrationsData = (
    rawOISMeterLocationsData: GetOisMeterLocationsQuery
) => {
    const originalLocations =
        rawOISMeterLocationsData.me.organization?.getLocations ?? [];

    const reformattedLocations = originalLocations.map((location) => {
        // we keep EPC integrations as they are
        const epcIntegrations = location.integrations.filter(
            (i) => i.type === IntegrationType.EPC
        );

        // we create fake OIS integrations based on the BBR number since BBR is the base of OIS integrations from the UX perspective
        const oisIntegrations = location.integrations.filter(
            (i) => i.type === IntegrationType.OIS
        );

        const oisIntegrationsGroupedByBbr = groupBy(
            oisIntegrations,
            (oisIntegration) =>
                (oisIntegration.data as IntegrationOisLocationIntegrationData)
                    .bbr
        );

        const reducedOisLocationIntegrationsWithBbrAsOriginId = Object.keys(
            oisIntegrationsGroupedByBbr
        ).map((bbr) => {
            const oisIntegration = oisIntegrationsGroupedByBbr[bbr][0];
            return {
                ...oisIntegration,
                originId: bbr,
            };
        });

        return {
            ...location,
            integrations: epcIntegrations.concat(
                reducedOisLocationIntegrationsWithBbrAsOriginId
            ),
        };
    });

    return {
        me: {
            ...rawOISMeterLocationsData.me,
            organization: {
                ...rawOISMeterLocationsData.me.organization,
                getLocations: reformattedLocations,
            },
        },
    } as GetOisMeterLocationsQuery;
};
