import { TFunction } from "i18next";
import _ from "lodash";

import {
    AssetIntegrationInput,
    CountryCode,
    CreateLocationInput,
    IntegrationType,
    LocationManagementType,
} from "graphql-types/graphql";
import { isValidNumber } from "utils/maths";

import { CSVProperty } from "./dataImportTypes";
import { parseDate } from "../DataUploadPage/utilities";

export const getApolloErrorMessages = (error: string, t: TFunction) => {
    if (error.includes("duplicate key")) {
        return t(
            "dataUploadPage.errors.duplicateKey",
            "File has duplicated values"
        );
    }

    return error;
};

const matchBBRCode = (bbrCode?: string) => {
    if (!bbrCode) {
        return null;
    }
    const regex = /(\d+)[- ]?(\d+)?[- ]?(\d+)? *(?:\((\d+)\))?/;
    const matches = bbrCode.match(regex);

    if (!matches) {
        console.log("No code matches", bbrCode);
        return null;
    }
    const [code, municipality, property, buildingNumber, useCode] = matches;

    return [
        code,
        municipality,
        property || "0",
        buildingNumber || "0",
        useCode,
    ] as const;
};

const getBBRInfo = (bbrCode: string) => {
    const matches = matchBBRCode(bbrCode);

    if (matches && matches[2] !== "0") {
        return {
            code: matches[0],
            data: {
                municipality: matches[1],
                property: matches[2],
                building: matches[3],
            },
        };
    }
    return null;
};

const externalStringMatches = ["ekstern", "external"];
const parseManagement = (item: CSVProperty) => {
    if (!item.Administration) {
        return;
    }

    const lowerCaseItemAdmin = item.Administration.toLocaleLowerCase();
    return externalStringMatches.some((match) =>
        lowerCaseItemAdmin.includes(match)
    )
        ? LocationManagementType.EXTERNAL
        : LocationManagementType.INTERNAL;
};

const parseCountryCode = (item: CSVProperty) => {
    if (!item.CountryCode) {
        return;
    }

    return Object.values(CountryCode).find((cc) => cc === item.CountryCode);
};

const getPartialEPC = (partial?: string) => {
    return isValidNumber(partial)
        ? { ownership: parseFloat(partial as string) }
        : {};
};

const getEPCIntegrations = (item: CSVProperty) => {
    if (item.BBRCode) {
        const bbrInfo = getBBRInfo(item.BBRCode);
        const partialEPC = getPartialEPC(item.PartialEPC);

        if (bbrInfo) {
            return [
                {
                    type: IntegrationType.EPC,
                    originId: bbrInfo.code,
                    data: { ...bbrInfo.data, ...partialEPC },
                },
            ];
        }
    }

    if (item.UKBuildingType && item.Address && item.Zip) {
        return [
            {
                type: IntegrationType.EPC_UK,
                originId: item.Address,
                data: {
                    buildingType: item.UKBuildingType,
                    address1: item.Address,
                    address2: null,
                    address3: null,
                    postcode: item.Zip,
                },
            },
        ];
    }
    return [];
};

type DisplayRow = {
    [key in keyof CreateLocationInput]: string;
} & {
    id: string;
    hasErrors: boolean;
    errors: {
        [key in keyof CreateLocationInput]: boolean;
    };
};

export const parseResults = (
    results: CSVProperty[]
): { locations: CreateLocationInput[] | null; rows: DisplayRow[] } => {
    const items = _.chain(results)
        .map((item, i) => {
            const epcIntegrations: AssetIntegrationInput[] =
                getEPCIntegrations(item);

            return {
                item,
                location: {
                    key: i,
                    externalId: item.BuildingId || undefined,
                    legalOwner: item.LegalOwner || undefined,
                    name: item.Name || undefined,
                    purchaseDate: parseDate(item.PurchaseDate),
                    saleDate: parseDate(item.SaleDate),
                    ownership: item.OwnerShare
                        ? parseInt(item.OwnerShare, 10)
                        : undefined,
                    buildingArea: item.Area ? parseFloat(item.Area) : undefined,
                    management: parseManagement(item),
                    integrations: epcIntegrations,
                    internalDescriptor:
                        item.InternalId ||
                        item.BBRCode ||
                        item.Address ||
                        undefined,
                    address: item.Address
                        ? JSON.stringify({
                              street: item.Address,
                              city: item.City,
                              zip: item.Zip,
                          })
                        : undefined,
                    countryCode: parseCountryCode(item),
                    bfeCodes: [item.BFECode],
                },
            };
        })
        .groupBy(
            (item) =>
                item.location.externalId ||
                item.location.name ||
                item.location.key
        )
        .map((group) => {
            const sorted = _.sortBy(group, (item) => item.location.key);
            const {
                item,
                location: { key, ...first },
            } = sorted[0];

            const areas = _.chain(sorted)
                .map((item) => item.location.buildingArea)
                .compact()
                .value();

            const buildingArea = areas.length ? _.sum(areas) : undefined;

            const location = {
                ...first,
                buildingArea,
                bfeCodes: _.compact(
                    _.flatMap(sorted, (item) => Number(item.location.bfeCodes))
                ),
                integrations: _.flatMap(
                    sorted,
                    (item) => item.location.integrations
                ),
            };

            const errors = {
                countryCode: !parseCountryCode(item),
            };

            const hasErrors = Object.values(errors).some((error) => error);

            return {
                location,
                row: {
                    ..._.mapValues(location, (value, key) => {
                        if (key === "address") {
                            return item.Address;
                        } else if (key === "bfeCodes") {
                            return value;
                        } else if (value instanceof Array) {
                            return value.map((v: any) => v.originId).join(", ");
                        }
                        return value?.toString();
                    }),
                    id: `${key}`,
                    errors,
                    hasErrors,
                } as DisplayRow,
            };
        })
        .value();

    const isAllLocationsValid = !items.some((i) => i.row.hasErrors);

    return {
        locations: isAllLocationsValid
            ? (items.map((i) => i.location) as CreateLocationInput[])
            : null,
        rows: items.map((i) => i.row),
    };
};
