import {
    styled,
    Table,
    TableRow,
    TableContainer,
    TableBody,
    useTheme,
    Select,
    MenuItem,
    TableCell,
    SelectChangeEvent,
} from "@mui/material";
import { TFunction } from "i18next";
import _, {
    capitalize,
    difference,
    get,
    has,
    keys,
    sortBy,
    uniqBy,
} from "lodash";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import FromToDatePicker from "components/DatePicker/FromToDatePicker";
import {
    CustomerLocationFragment,
    DexmaMeterLocationFragment,
    EntolabsMeterLocationFragment,
    HoforMeterLocationFragment,
    IntegrationType,
    SupportedIntegrationForOnboarding,
    TechemMeterLocationFragment,
    VitaniMeterLocationFragment,
} from "graphql-types/graphql";
import { NO_NAME_OR_ADDRESS_FOR_LOCATION } from "utils/strings.helpers";

import {
    customerLocationAndRowIndex,
    DropDownItem,
    initialStateSelection,
    MeterLocation,
    OisMeterLocation,
} from "../../types";
import { UNSUPPORTED_INTEGRATION_TYPE } from "../../utils";
import { LocationMapping, SelectionsState } from "../sharedModalTypes";

const TableCellWrapper = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(2),
    fontWeight: "normal",
}));

const StyledTypo = styled(TableCell)({
    display: "flex",
    justifyContent: "center",
});

type Props = {
    meterLocations: MeterLocation[];
    meterLocationDropdownItems: DropDownItem[];
    customerLocation: CustomerLocationFragment;
    integrationType: SupportedIntegrationForOnboarding;
    cellValue: string;
    clickHandler: (
        meterLocations: MeterLocation[],
        customerLocation: CustomerLocationFragment,
        rowIndex: number,
        newSelectedMeterLocationDropdownItems: DropDownItem[],
        selectedDropdownValue: LocationMapping | null,
        deselectedDropdownValue: LocationMapping | null,
        deselectedRowDropdownTitle?: string
    ) => void;
    id: string | number;
    rowIndex: number;
    selectionsState: SelectionsState;
    handleIntegrationDates: (
        from: Date | null,
        to: Date | null,
        values: string[],
        integrationType: SupportedIntegrationForOnboarding
    ) => void;
};

interface TableComponentProps {
    rows: JSX.Element[];
    integrationType: SupportedIntegrationForOnboarding;
}

export const getDisplayValueForLocation = (l: CustomerLocationFragment) => {
    const locationIdentifier = l.displayName || l.shortAddress || l.longAddress;

    if (locationIdentifier) {
        return locationIdentifier;
    }

    const building = l.buildings.find(
        (b) => b.name || b.shortAddress || b.longAddress || b.nationalIdentifier
    );

    const buildingName = building
        ? building.name ||
          building.shortAddress ||
          building.longAddress ||
          building.nationalIdentifier
        : null;

    return buildingName || l.externalId || NO_NAME_OR_ADDRESS_FOR_LOCATION;
};

export const getMeterLocationDropdownValue = (
    l: MeterLocation,
    integration: SupportedIntegrationForOnboarding
): { title: string; value: string } | { title: string; value: string }[] => {
    let parsedLocation: MeterLocation;
    switch (integration) {
        case SupportedIntegrationForOnboarding.DEXMA:
            parsedLocation = l as DexmaMeterLocationFragment;

            return {
                title: `${parsedLocation.name} - (${parsedLocation.key})`,
                value: parsedLocation.key,
            };
        case SupportedIntegrationForOnboarding.TECHEM:
            parsedLocation = l as TechemMeterLocationFragment;
            return {
                title: parsedLocation.property_number,
                value: parsedLocation.property_number,
            };
        case SupportedIntegrationForOnboarding.VITANI:
            parsedLocation = l as VitaniMeterLocationFragment;
            return {
                title: `${parsedLocation.Name} - (${parsedLocation.DirectoryID})`,
                value: parsedLocation.DirectoryID.toString(),
            };
        case SupportedIntegrationForOnboarding.HOFOR:
            parsedLocation = l as HoforMeterLocationFragment;
            return {
                title: `${parsedLocation.installationNumber}`,
                value: parsedLocation.installationNumber,
            };
        case SupportedIntegrationForOnboarding.OIS:
            parsedLocation = l as OisMeterLocation;

            return parsedLocation.integrations
                .filter((i) => i.type === IntegrationType.EPC)
                .map((a) => {
                    return {
                        title: `${a.originId}`,
                        value: a.originId,
                    };
                });
        case SupportedIntegrationForOnboarding.ENTOLABS: {
            const { sourceId, meterType, address, siteName, meterId } =
                l as EntolabsMeterLocationFragment;

            return {
                title: `${sourceId} -
                ${capitalize(meterType.replace("_", " "))} -
                ${address || siteName}`,
                value: meterId,
            };
        }
        default:
            throw new Error(UNSUPPORTED_INTEGRATION_TYPE);
    }
};

export const getInitialMeterLocationDropdownValues = (
    l: initialStateSelection[],
    integration: SupportedIntegrationForOnboarding
) => {
    switch (integration) {
        case SupportedIntegrationForOnboarding.TECHEM: {
            const selectedTechem = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const { property_number } =
                        b as TechemMeterLocationFragment;

                    return { [property_number.toString()]: a.rowIndex };
                })
            );

            return Object.assign({}, ...Object.values(selectedTechem));
        }
        case SupportedIntegrationForOnboarding.DEXMA: {
            const selectedDexma = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const { key } = b as DexmaMeterLocationFragment;

                    return { [key.toString()]: a.rowIndex };
                })
            );

            return Object.assign({}, ...Object.values(selectedDexma));
        }
        case SupportedIntegrationForOnboarding.VITANI: {
            const selectedVitani = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const { DirectoryID } = b as VitaniMeterLocationFragment;

                    return { [DirectoryID.toString()]: a.rowIndex };
                })
            );

            return Object.assign({}, ...Object.values(selectedVitani));
        }
        case SupportedIntegrationForOnboarding.HOFOR: {
            const selectedHofor = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const { installationNumber } =
                        b as HoforMeterLocationFragment;

                    return {
                        [installationNumber.toString()]: a.rowIndex,
                    };
                })
            );

            return Object.assign({}, ...Object.values(selectedHofor));
        }
        case SupportedIntegrationForOnboarding.OIS: {
            const selectedOis = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    return {
                        [`${(b as OisMeterLocation).integrations
                            .filter((i) => i.type === IntegrationType.OIS)
                            .map((a) => a.originId)}`]: a.rowIndex,
                    };
                })
            );

            return Object.assign({}, ...Object.values(selectedOis));
        }
        case SupportedIntegrationForOnboarding.ENTOLABS: {
            const selectedEntolabs = l.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const { meterId } = b as EntolabsMeterLocationFragment;

                    return {
                        [meterId.toString()]: a.rowIndex,
                    };
                })
            );

            return Object.assign({}, ...Object.values(selectedEntolabs));
        }
        default:
            throw new Error(UNSUPPORTED_INTEGRATION_TYPE);
    }
};

const getInitialIntegrationDates = (
    customerLocation: CustomerLocationFragment,
    integrationType: SupportedIntegrationForOnboarding
) => {
    const integration = customerLocation?.integrations?.find(
        (i) => i.type === IntegrationType[integrationType]
    );

    return {
        initialStartedAt: integration?.startedAt
            ? DateTime.fromISO(integration.startedAt)
            : null,
        initialEndedAt: integration?.endedAt
            ? DateTime.fromISO(integration.endedAt)
            : null,
    };
};

const TableRowComponent = (props: Props) => {
    const {
        meterLocations,
        meterLocationDropdownItems,
        customerLocation,
        integrationType,
        cellValue,
        clickHandler,
        rowIndex,
        selectionsState,
        handleIntegrationDates,
    } = props;
    const theme = useTheme();

    const { initialStartedAt, initialEndedAt } = useMemo(
        () => getInitialIntegrationDates(customerLocation, integrationType),
        [customerLocation, integrationType]
    );

    const [integrationStartedAt, setIntegrationStartedAt] =
        useState<DateTime | null>(initialStartedAt);
    const [integrationEndedAt, setIntegrationEndedAt] =
        useState<DateTime | null>(initialEndedAt);

    const values = keys(selectionsState.selectedMeterLocationDropdownValues)
        .filter(
            (k) =>
                get(selectionsState.selectedMeterLocationDropdownValues, k) ===
                rowIndex
        )
        .flatMap((k) =>
            integrationType === SupportedIntegrationForOnboarding.OIS
                ? k.split(",")
                : k
        );

    const dropdownValues = uniqBy(values, (v) => v);

    const handleIntegrationDateChange = useCallback(
        (from: DateTime | null, to: DateTime | null) => {
            const newStartedAt = from?.toJSDate() ?? null;
            const newEndedAt = to?.toJSDate() ?? null;

            setIntegrationStartedAt(() => from);
            setIntegrationEndedAt(() => to);

            const isMappingDropdownEmpty = _.compact(values).length === 0;
            if (isMappingDropdownEmpty) return;

            handleIntegrationDates(
                newStartedAt,
                newEndedAt,
                values,
                integrationType
            );
        },
        [values, integrationType, handleIntegrationDates]
    );

    const handleChange = useCallback(
        (event: SelectChangeEvent<string[]>) => {
            const selectedDropdownValues = event.target.value;

            const newSelectedMeterLocationDropdownValues =
                meterLocationDropdownItems.filter((l) =>
                    selectedDropdownValues.includes(l.value)
                );

            const selectedMeterLocations = meterLocations.filter((l) => {
                const locationDropdownValue = getMeterLocationDropdownValue(
                    l,
                    integrationType
                );

                return !Array.isArray(locationDropdownValue)
                    ? selectedDropdownValues.includes(
                          locationDropdownValue.value
                      )
                    : locationDropdownValue.some((l) =>
                          selectedDropdownValues.includes(l.value)
                      );
            });

            const deselectedDropdownValue = _.last(
                difference(dropdownValues, selectedDropdownValues)
            );
            const selectedDropdownValue = _.last(selectedDropdownValues);
            const selectedDropdownValueIntegrationData =
                !selectedDropdownValue || deselectedDropdownValue
                    ? undefined
                    : getMeterLocationForSelectedValue(
                          selectedDropdownValue,
                          integrationType,
                          meterLocations
                      );

            const lastSelectedValue =
                deselectedDropdownValue ?? selectedDropdownValue;
            const originId = lastSelectedValue
                ? meterLocationDropdownItems.find((location) =>
                      location.value.includes(lastSelectedValue)
                  )?.value ?? ""
                : "";

            const deselectedLocationMapping = deselectedDropdownValue
                ? { originId, assetId: customerLocation.id }
                : null;

            const selectedLocationMapping =
                selectedDropdownValue && !deselectedDropdownValue
                    ? {
                          originId,
                          assetId: customerLocation.id,
                          data: selectedDropdownValueIntegrationData,
                          startedAt: integrationStartedAt?.toJSDate(),
                          endedAt: integrationEndedAt?.toJSDate(),
                      }
                    : null;

            clickHandler(
                selectedMeterLocations,
                customerLocation,
                rowIndex,
                newSelectedMeterLocationDropdownValues,
                selectedLocationMapping,
                deselectedLocationMapping,
                deselectedDropdownValue
            );
        },
        [
            clickHandler,
            customerLocation,
            dropdownValues,
            integrationType,
            meterLocationDropdownItems,
            meterLocations,
            rowIndex,
            integrationStartedAt,
            integrationEndedAt,
        ]
    );

    const options = meterLocations.flatMap((l, i) => {
        const dropdownValue = getMeterLocationDropdownValue(l, integrationType);

        // disable if selected and was selected in another dropdown row
        const isSelectedOnOtherCustomerLocation = Array.isArray(dropdownValue)
            ? false
            : has(
                  selectionsState.selectedMeterLocationDropdownValues,
                  dropdownValue.value
              ) &&
              get(
                  selectionsState.selectedMeterLocationDropdownValues,
                  dropdownValue.value
              ) !== rowIndex;

        return !Array.isArray(dropdownValue) ? (
            <MenuItem
                disabled={isSelectedOnOtherCustomerLocation}
                key={i}
                value={dropdownValue.value}
                id={`meter-location-${i}`}
            >
                {dropdownValue.title}
            </MenuItem>
        ) : (
            dropdownValue.map((d, index) => (
                <MenuItem
                    key={`${i}-${index}`}
                    disabled={isSelectedOnOtherCustomerLocation}
                    value={d.value}
                    id={`meter-location-${i}-${index}`}
                >
                    {d.title}
                </MenuItem>
            ))
        );
    });

    return (
        <TableRow hover>
            <TableCellWrapper style={{ paddingLeft: `${theme.spacing(6)}px` }}>
                {cellValue}
            </TableCellWrapper>
            <TableCellWrapper>
                <Select
                    multiple
                    required
                    style={{ width: "100%" }}
                    size="small"
                    value={dropdownValues}
                    onChange={handleChange}
                >
                    {options}
                </Select>
            </TableCellWrapper>
            <TableCellWrapper>
                <FromToDatePicker
                    from={integrationStartedAt}
                    to={integrationEndedAt}
                    onChange={handleIntegrationDateChange}
                />
            </TableCellWrapper>
        </TableRow>
    );
};

export const TableComponent = ({
    rows,
    integrationType,
}: TableComponentProps) => {
    const { t } = useTranslation();

    const content = rows.length ? (
        rows
    ) : (
        <TableRow>
            <StyledTypo>
                {t(
                    "integrationOnboarding.noMeterLocationsLabel",
                    "No locations found in",
                    { integrationType }
                )}
            </StyledTypo>
        </TableRow>
    );

    return (
        <TableContainer>
            <Table sx={{ tableLayout: "fixed" }}>
                <TableBody>{content}</TableBody>
            </Table>
        </TableContainer>
    );
};

export const getMappedMeterLocationRows = (
    meterLocations: MeterLocation[],
    meterLocationDropdownItems: DropDownItem[],
    customerLocations: customerLocationAndRowIndex[],
    integrationType: SupportedIntegrationForOnboarding,
    clickHandler: (
        meterLocations: MeterLocation[],
        customerLocation: CustomerLocationFragment,
        rowIndex: number,
        newSelectedMeterLocationDropdownItems: DropDownItem[],
        selectedDropdownValue: LocationMapping | null,
        deselectedDropdownValue: LocationMapping | null,
        deselectedRowDropdownTitle?: string
    ) => void,
    selectionsState: SelectionsState,
    handleIntegrationDates: (
        from: Date | null,
        to: Date | null,
        values: string[],
        integrationType: SupportedIntegrationForOnboarding
    ) => void
) => {
    const sortedCustomerLocations = sortBy(customerLocations, (l) =>
        getDisplayValueForLocation(l)
    );

    return sortedCustomerLocations.map((l: customerLocationAndRowIndex, i) => {
        const meterLocationsValues =
            integrationType === SupportedIntegrationForOnboarding.OIS
                ? meterLocations.filter(
                      (value) =>
                          value.__typename === "Location" && l.id === value.id
                  )
                : meterLocations;

        return (
            <TableRowComponent
                meterLocations={meterLocationsValues}
                meterLocationDropdownItems={meterLocationDropdownItems}
                customerLocation={l}
                integrationType={integrationType}
                cellValue={getDisplayValueForLocation(l)}
                clickHandler={clickHandler}
                key={i}
                id={l.id}
                selectionsState={selectionsState}
                rowIndex={l.rowIndex}
                handleIntegrationDates={handleIntegrationDates}
            />
        );
    });
};

export function getOnboardingInputTranslatedPlaceholder(
    t: TFunction,
    key: string
) {
    switch (key) {
        case "baseurl":
            return t("integrationOnboarding.baseurl", "Base Url");
        case "username":
            return t("integrationOnboarding.username", "Username");
        case "password":
            return t("integrationOnboarding.password", "Password");
        case "privatekey":
            return t("integrationOnboarding.privatekey", "Private Key");
        case "passphrase":
            return t("integrationOnboarding.passphrase", "Passphrase");
        case "apikey":
            return t("integrationOnboarding.apiKey", "API key");
        case "entolabsorganizationids":
            return t(
                "integrationOnboarding.organizationId",
                "The integration organization ID"
            );
        default:
            return "";
    }
}

export const getMeterLocationForSelectedValue = (
    value: string,
    integrationType: SupportedIntegrationForOnboarding,
    meterLocations: MeterLocation[]
) => {
    return meterLocations.find((l) => {
        const locationIntegrationData = getMeterLocationDropdownValue(
            l,
            integrationType
        );

        return !Array.isArray(locationIntegrationData)
            ? value.includes(locationIntegrationData.value)
            : locationIntegrationData.some((l) => value.includes(l.value));
    });
};
