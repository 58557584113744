import { gql } from "@apollo/client";

const AssessmentSummariesFragment = gql`
    fragment AssessmentSummaries on AssessmentSummary {
        id
        from
        to
        sources
        vertical
        epcData {
            classification
        }
        emissionDataGrams {
            emission {
                meter {
                    ownedEmission
                }
                epc {
                    ownedEmission
                }
                bestEffort {
                    ownedEmission
                }
            }
            emissionPerArea {
                meter {
                    ownedEmission
                }
                epc {
                    ownedEmission
                }
                bestEffort {
                    ownedEmission
                }
            }
        }
    }
`;

const AssetListLocationFragment = gql`
    fragment AssetListLocation on Location {
        id
        name
        ownedArea
        purchaseDate
        externalId
        displayName
        shortAddress
        longAddress
        saleDate
        buildingArea
        buildings {
            nationalIdentifier
            shortAddress
        }
        assessmentSummaries(
            filter: {
                from: { between: { lower: $fromLower, upper: $fromUpper } }
            }
        ) {
            ...AssessmentSummaries
        }
        assetAlerts(
            filter: {
                from: { between: { lower: $fromLower, upper: $fromUpper } }
            }
        ) {
            id
            to
            from
            type
            data {
                ... on EpcExpiresSoonAlertData {
                    epcExpiryDates
                }
            }
        }
        dataQuality(
            from: $fromLower
            to: $fromUpper
            ignoreProxy: false
            filter: { dataType: $assessmentDataType }
        ) {
            from
            to
            highQuality {
                sources
                assessmentDays
                priorityDistribution
            }
            mediumQuality {
                sources
                assessmentDays
                priorityDistribution
            }
            lowQuality {
                sources
                assessmentDays
                priorityDistribution
            }
        }
        integrations(filter: { type: { in: [EPC, EPC_DK] } }) {
            id
            originId
        }
        benchmarkPerformances(
            filter: {
                from: { between: { lower: $fromLower, upper: $fromUpper } }
            }
        ) {
            to
            from
            dataType
            benchmarkType
            performance
            ownedEmission
            benchmarkId
        }
    }
`;

export const ASSET_LIST_QUERY = gql`
    query GetAssetListAssessments(
        $fromLower: DateTime!
        $fromUpper: DateTime!
        $assessmentDataType: AssessmentDataType!
        $assetGroupId: ID
    ) {
        getAssets(
            from: $fromLower
            to: $fromUpper
            assetGroupId: $assetGroupId
        ) {
            ...AssetListLocation
        }
    }
    ${AssetListLocationFragment}
    ${AssessmentSummariesFragment}
`;
