import { Container, Link } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DownloadCell } from "components/Cells/DownloadCell";
import Loading from "components/Loading/Loading";
import { Table, TablePagination } from "components/Table";
import {
    AssessmentDataType,
    ReportFramework,
    ReportEnergyConversionFactorQuery,
} from "graphql-types/graphql";
import { isValidUrl } from "utils/report.helpers";

import { REPORT_ENERGY_CONVERSION_FACTORS } from "./reportQueries";
import { useReportQuery } from "./useReportQuery";

export const ReportEnergyConversionFactor = ({
    reportType,
    dataType,
}: {
    reportType: ReportFramework;
    dataType: AssessmentDataType;
}) => {
    const { t } = useTranslation();

    const { data, loading } = useReportQuery<ReportEnergyConversionFactorQuery>(
        REPORT_ENERGY_CONVERSION_FACTORS,
        dataType,
        reportType
    );

    const columns = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.getReportEnergyConversionFactorHeaders.map(
            ({ key, header }, index) => ({
                field: key,
                headerName: header,
                width: index < 6 ? 200 : 450,
                renderCell: (params: GridRowModel) => {
                    const isSourceDownloadLink = key === "sourceDownloadLink";
                    const isExternalSource = key === "externalSourceLink";
                    const isSourceLink = header.includes("Source");

                    if (isSourceDownloadLink && isValidUrl(params.value)) {
                        return (
                            <DownloadCell
                                downloadLink={params.value as string}
                                isCentered={false}
                            />
                        );
                    }

                    if (
                        (isSourceLink || isExternalSource) &&
                        isValidUrl(params.value)
                    ) {
                        return (
                            <Link
                                href={params.value}
                                target="_blank"
                                rel="noopener"
                            >
                                {params.value}
                            </Link>
                        );
                    }
                },
            })
        );
    }, [data]);

    const rows = useMemo(
        () => data?.getReportEnergyConversionFactor || [],
        [data]
    );

    if (loading || !data) {
        return (
            <Container sx={{ textAlign: "center", padding: 2 }}>
                {loading ? (
                    <Loading
                        description={t(
                            "labels.loading",
                            "We are processing your report",
                            { ns: "report" }
                        )}
                    />
                ) : (
                    t(
                        "assessment.common.noData",
                        "No available data for this period."
                    )
                )}
            </Container>
        );
    }

    return (
        <Table
            isLoading={loading}
            rows={rows}
            columns={columns}
            slotProps={{
                pagination: {
                    ActionsComponent: TablePagination,
                },
            }}
        />
    );
};
