import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";

import { FormState, HandleChangeEventTarget } from "../types";

type Props = {
    formValues: FormState;
    integrations: readonly SupportedIntegrationForOnboarding[];
    handleChange: (e: { target: HandleChangeEventTarget }) => void;
    onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
};

const IntegrationSelector = (props: Props) => {
    const { t } = useTranslation();

    const { formValues, integrations, handleChange, onSubmit } = props;

    return (
        <form onSubmit={onSubmit}>
            <FormControl fullWidth>
                <InputLabel id="integration-select-label">
                    {t(
                        "integrationOnboarding.selectIntegration",
                        "Select integration"
                    )}
                </InputLabel>

                <Select
                    sx={{ mb: 2 }}
                    labelId="integration-select-label"
                    name="integration"
                    label="integrations"
                    value={formValues.integration}
                    onChange={handleChange}
                    required
                >
                    {integrations.map((int, i) => (
                        <MenuItem key={i} value={int} id={`integration-${int}`}>
                            {int}
                        </MenuItem>
                    ))}
                </Select>

                <Button variant="contained" type="submit" size="large">
                    {t("integrationOnboarding.continue", "Continue")}
                </Button>
            </FormControl>
        </form>
    );
};

export default IntegrationSelector;
