import { useMutation, useQuery } from "@apollo/client";
import { ElectricalServices, Handyman } from "@mui/icons-material";
import { Button, Grid, Paper, Typography, styled } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Loading from "components/Loading/Loading";
import { ADD_ORGANIZATION_INTEGRATION_MUTATION } from "containers/IntegrationOnboarding/graphql/organizationIntegrationOnboardingMutation";
import { ACTIVE_INTEGRATIONS } from "containers/InternalToolboxPage/IntegrationSetup/integrationQueries";
import {
    ActiveOrgIntegrationsQuery,
    AddOrganizationIntegrationMutation,
    IntegrationType,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";
import { getIntegrationTypeTranslation } from "utils/translations";

const ENABLED_INTEGRATIONS = [
    SupportedIntegrationForOnboarding.ELECTRALINK,
    SupportedIntegrationForOnboarding.SOFTVAERKET,
];

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

type IntegrationLinkProps = {
    integration: SupportedIntegrationForOnboarding;
    isActive: boolean;
    onClick: (tab: SupportedIntegrationForOnboarding) => void;
};

const IntegrationLink = (props: IntegrationLinkProps) => {
    const { integration, isActive, onClick } = props;

    const { t } = useTranslation();

    const Icon = !isActive ? ElectricalServices : Handyman;

    return (
        <Item>
            <Typography variant="h3">
                {getIntegrationTypeTranslation(integration).toUpperCase()}
            </Typography>

            <Button
                size="large"
                startIcon={<Icon />}
                disabled={!ENABLED_INTEGRATIONS.includes(integration)}
                onClick={() => onClick(integration)}
                color={isActive ? "success" : "primary"}
                variant="outlined"
            >
                {isActive
                    ? t("integrationOnboarding.configure", "Configure")
                    : t("integrationOnboarding.connect", "Connect")}
            </Button>
        </Item>
    );
};

type Props = {
    setActiveTab: (tab: SupportedIntegrationForOnboarding | null) => void;
};

const OrganizationIntegrationPage = (props: Props) => {
    const { setActiveTab } = props;

    const { t } = useTranslation();
    const { useSoftvaerketIntegration } = useFlags();

    const { data, loading } =
        useQuery<ActiveOrgIntegrationsQuery>(ACTIVE_INTEGRATIONS);

    const [addOrganizationIntegration] =
        //NOTE: Only works for organizations without data (ex: ELECTRALINK)
        useMutation<AddOrganizationIntegrationMutation>(
            ADD_ORGANIZATION_INTEGRATION_MUTATION,
            { refetchQueries: [ACTIVE_INTEGRATIONS] }
        );

    const handleClick = async (
        integrationType: SupportedIntegrationForOnboarding
    ) => {
        if (
            activeIntegrations.includes(
                integrationType as unknown as IntegrationType
            )
        )
            return setActiveTab(integrationType);

        toast.promise(
            addOrganizationIntegration({
                variables: {
                    input: {
                        organizationIntegration: {
                            type: integrationType,
                        },
                    },
                },
            }),
            {
                success: t(
                    "integrationOnboarding.success",
                    `{{integrationType}} integration created!`,
                    { integrationType }
                ),
                error: t("integrationOnboarding.error", "Error!"),
            }
        );
    };

    const activeIntegrations =
        data?.me.organization?.integrations?.map(
            (integration) => integration.type
        ) ?? [];

    const integrations = ENABLED_INTEGRATIONS.filter((integration) =>
        useSoftvaerketIntegration
            ? true
            : integration !== SupportedIntegrationForOnboarding.SOFTVAERKET
    );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <Grid container spacing={4}>
            {integrations.map((integration) => (
                <Grid item key={integration} xs={12} md={6} xl={4}>
                    <IntegrationLink
                        integration={integration}
                        onClick={handleClick}
                        isActive={activeIntegrations.includes(
                            integration as unknown as IntegrationType
                        )}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default OrganizationIntegrationPage;
