import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Drawer, Tab, Tag } from "glue";
import {
    Property,
    GetPropertyQuery,
    GetPropertyQueryVariables,
} from "graphql-types/graphql";
import { getPropertyObjectTypeTranslation } from "utils/translations";

import { useFormatPropertyDetails } from "./asset-property-drawer.helpers";
import { S } from "./asset-property-drawer.style";
import { GET_PROPERTY } from "../AssetPropertyList";

type Props = {
    isOpen: boolean;
    property: Property;
    onClose: () => void;
};

export const AssetPropertyDrawer = (props: Props) => {
    const { property, isOpen, onClose } = props;

    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetPropertyQuery,
        GetPropertyQueryVariables
    >(GET_PROPERTY, {
        variables: { id: property.id },
    });

    const details = useFormatPropertyDetails(data?.property, t);

    const list = (
        <S.Panel>
            {details.map((item) => (
                <S.Container key={item.label}>
                    <Typography fontWeight="bold">{item.label}</Typography>
                    <S.Typography loading={loading}>
                        {item.data || "-"}
                    </S.Typography>
                </S.Container>
            ))}
        </S.Panel>
    );

    return (
        <Drawer width="30%" anchor="right" isOpen={isOpen} onClose={onClose}>
            <Drawer.Header>
                <Drawer.Title>
                    <Tag
                        color="info"
                        label={getPropertyObjectTypeTranslation(
                            property.objectType
                        ).toUpperCase()}
                    />
                    {property.name}
                </Drawer.Title>
                <Drawer.Panel>{property.longAddress}</Drawer.Panel>
            </Drawer.Header>
            <Drawer.Content>
                <S.Tabs>
                    <Tab
                        label={t(
                            "assetDetailsComponent.propertyDetails",
                            "property details"
                        )}
                    >
                        {list}
                    </Tab>
                </S.Tabs>
            </Drawer.Content>
        </Drawer>
    );
};
