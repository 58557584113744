import { Box, styled } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";

import { UnitMass } from "components/AssessmentStats/types";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import { useSelectedYearContext } from "../../YearSelector/YearContext";
import { useGetMonthlyEmissionSummaries } from "../Charts/charts.helper";
import YearlyBreakdownCharts from "../Charts/YearlyBreakdownCharts";
import {
    getSumAssetsEmissionSummariesOwnedEmissions,
    getTotalArea,
} from "../helpers/utilities";
import PortfolioDataQuality from "../PortfolioDataQuality";
import PortfolioTitleNumberBox from "../PortfolioTitleNumberBox";
import { ChartType } from "../types";

const YearlyBreakdownContainer = styled(Box)(() => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 75%",
    gap: 12,
    alignItems: "start",
}));

const PortfolioBlockRow = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
}));

const YearlyBreakdown = () => {
    const { assessmentType } = useAssessmentDataTypeContext();

    const { activeYear } = useSelectedYearContext();

    const [activeChart, setActiveChart] = useState<ChartType>(
        ChartType.MARKET_PERFORMANCE
    );

    const { emissionSummaries, loading: isEmissionSummariesQueryLoading } =
        useGetMonthlyEmissionSummaries(activeYear, assessmentType);

    const { assetsWithDataInCurrentYear, summedAreas, ownedEmission } =
        useMemo(() => {
            const assetsWithDataInCurrentYear = emissionSummaries.filter(
                (asset) => asset.emissionSummaries.length > 0
            );

            return {
                assetsWithDataInCurrentYear,
                summedAreas: getTotalArea(assetsWithDataInCurrentYear),
                ownedEmission: getSumAssetsEmissionSummariesOwnedEmissions(
                    assetsWithDataInCurrentYear
                ),
            };
        }, [emissionSummaries]);

    return (
        <YearlyBreakdownContainer>
            <PortfolioBlockRow>
                <PortfolioTitleNumberBox
                    title={t(
                        "portfolioDashboard.boxes.assets",
                        "Active Assets"
                    )}
                    number={assetsWithDataInCurrentYear?.length || 0}
                />
                <PortfolioTitleNumberBox
                    title={t("portfolioDashboard.boxes.area", "Total Area")}
                    number={
                        formatNumberLocale(summedAreas, t, { max: 0 }) +
                        " " +
                        t("portfolioDashboard.boxes.sqm", "m²")
                    }
                />
                <PortfolioTitleNumberBox
                    title={t(
                        "portfolioDashboard.boxes.emissions",
                        "Total Emissions"
                    )}
                    number={
                        formatNumberLocale(
                            getConvertedGramsToMassUnit(
                                ownedEmission,
                                UnitMass.TON
                            ),
                            t,
                            { max: 0 }
                        ) +
                        " " +
                        t("common.units.emission.tonnes", "t CO2e", {
                            ns: "translation",
                        })
                    }
                />

                <PortfolioDataQuality activeTab={assessmentType} />
            </PortfolioBlockRow>
            <YearlyBreakdownCharts
                activeChart={activeChart}
                setActiveChart={setActiveChart}
                activeTab={assessmentType}
                emissionSummaries={emissionSummaries}
                isLoading={isEmissionSummariesQueryLoading}
            />
        </YearlyBreakdownContainer>
    );
};

export default YearlyBreakdown;
