import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Property } from "graphql-types/graphql";

type Props = {
    isOpen: boolean;
    assetProperty: Property | null;
    onClose: () => void;
    onConfirm: (assetProperty: Property | null) => void;
};

export const RemovePropertyDialog = (props: Props) => {
    const { isOpen, onClose, onConfirm, assetProperty } = props;

    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} sx={{ "& .MuiDialog-paper": { maxWidth: 400 } }}>
            <DialogTitle variant="h4">
                {t("assetDetailsComponent.removeProperty", "remove property")}
            </DialogTitle>
            <DialogContent dividers>
                {t(
                    "assetDetailsComponent.deletePropertyDialogLabel",
                    "are you sure you want to delete this property? This cannot be undone."
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onConfirm(assetProperty)}
                    color="error"
                >
                    {t("labels.delete", "Delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
