import { gql } from "@apollo/client";

export const AUTOMATIC_SOURCES = gql`
    fragment AutomaticSource on LocationIntegration {
        id
        from
        to
        isActive
        endedAt
        type
        address
        identifier
        dataQuality
        consumptionTypes
        allocationNote
        allocations {
            ...AutomaticSourceAllocation
        }
    }
`;

export const AUTOMATIC_SOURCE_ALLOCATION = gql`
    fragment AutomaticSourceAllocation on AssetIntegrationAllocation {
        percentage
        propertyId
        assetId
        property {
            objectType
        }
    }
`;

export const ASSET_AUTOMATIC_SOURCES = gql`
    query GetAssetAutomaticSources($locationId: ID!) {
        location(id: $locationId) {
            id
            displayName
            longAddress
            automaticDataSources {
                ...AssetAutomaticDataSourceItem
            }
        }
    }
    fragment AssetAutomaticDataSourceItem on AssetAutomaticDataSource {
        id
        from
        to
        type
        consumptionType
        identifier
        dataQuality
        address
        nationalPropertyId
        vertical
        status
        source
        allocationNote
        integrationId
        allocations {
            ...AutomaticSourceAllocation
        }
    }
    ${AUTOMATIC_SOURCE_ALLOCATION}
`;

export const PROPERTY_INFO = gql`
    fragment PropertyInfoFragment on Property {
        id
        name
        objectType
    }
`;

export const GET_PROPERTY_TREE_FOR_ALLOCATION = gql`
    query GetPropertyTreeForAllocation($assetId: ID!) {
        getAssetPropertyTree(assetId: $assetId) {
            ...PropertyInfoFragment
            children {
                ...PropertyInfoFragment
                children {
                    ...PropertyInfoFragment
                    children {
                        ...PropertyInfoFragment
                        children {
                            ...PropertyInfoFragment
                            children {
                                ...PropertyInfoFragment
                                children {
                                    ...PropertyInfoFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${PROPERTY_INFO}
`;

export const GET_ASSET_AUTOMATIC_SOURCES = gql`
    query GetAllAssetIntegrations(
        $filter: LocationIntegrationFilter!
        $paging: CursorPaging!
        $sorting: LocationIntegrationSort!
    ) {
        locationIntegrations(
            filter: $filter
            paging: $paging
            sorting: [$sorting]
        ) {
            edges {
                node {
                    ...AutomaticSource
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
        }
    }
    ${AUTOMATIC_SOURCES}
    ${AUTOMATIC_SOURCE_ALLOCATION}
`;

export const GET_ASSET_AUTOMATIC_SOURCES_COUNT = gql`
    query GetAssetAutomaticSourcesCount($assetId: String!) {
        locationIntegrations(filter: { assetId: { eq: $assetId } }) {
            totalCount
        }
    }
`;

export const GET_ASSET_AUTOMATIC_SOURCES_OPTIONS = gql`
    query GetAssetAutomaticSourcesOptions($assetId: ID!) {
        location(id: $assetId) {
            integrationTypes
            consumptionTypes
        }
    }
`;
